import { Outlet } from "react-router";
import React from "react";

interface LayoutProps {}

const Layout: React.FunctionComponent<LayoutProps> = () => {

  return (
    <>
      {process.env.REACT_APP_BANNER ? (
        <div className="w-full text-center bg-pink text-white">
          {process.env.REACT_APP_BANNER}
        </div>
      ) : null}
      <Outlet />
    </>
  );
};

export default Layout;
